import styled from "styled-components";

const SelectBoxWrapper = styled.div`
	display: flex;
`;

export const Select = styled.select`	
	min-width: 0;
	display: block;
	width: 85px;
	height: 25px;
	padding: 1px 8px;
	font-size: 12px;
	line-height: inherit;
	border: 1px solid;
	border-radius: 4px;
	color: black;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		border-color: red;
	}
`;

const IconSVG = styled.svg`
	margin-left: -18px;
	align-self: center;
	width: 18px;
	height: 24px;
`;

export function CustomSelect(props){
	const handleChange = (e) => {		
		props.callSlide(props.id, e.target.value);
	};

	return (
		<SelectBoxWrapper>
			<Select id={props.id} onChange={handleChange} defaultValue={props.defaultValue}>
				{props.options.map((option) => (					
					<option
						key={option.value}
						value={option.value}	
					>
					{option.name}					
					</option>
				))}
			</Select>			
			<IconSVG
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 14L16 6H4L10 14Z"
					fill="#1A1A1A"
				/>
			</IconSVG>
		</SelectBoxWrapper>
	);
}
