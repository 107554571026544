/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Chart } from 'react-google-charts';

const _ = require('lodash');

export function TableExchangeRate(props) {  
  const { id, exchangeData } = props;  

  if (_.isEmpty(exchangeData)) {
    return;
  }

  function getTableData(id, exchangeData) {    
    if (id === 'exchangerate-real-table') {
      const rows = exchangeData;
      const header = [
        { type: 'string', label: '채널명' },
        { type: 'string', label: '매매기준율' },
        { type: 'string', label: '기준일자' },
        { type: 'string', label: '기준시간' },
      ];
      return [header, ...rows];
      
    } else if (id === 'exchangerate-bank-table') {
      const rows = exchangeData;
      const header = [
        { type: 'string', label: '은행명' },
        { type: 'string', label: '매매기준율' },
        { type: 'number', label: '갭차' },
        { type: 'string', label: '기준일자' },
        { type: 'string', label: '기준시간' },
      ];
      return [header, ...rows];
    }
  }

  const tableData = getTableData(id, exchangeData);

  return (
    <div
      css={css`
          // PC에서 테이블이 불필요하게 크게 보이는 것을 제한
          max-width: 640px;
          margin: 1px auto;
          margin-bottom: 0px;

          .diff.green {
            color: green;
          }

          .diff.red {
            color: red;
          }

          // 구글 테이블 차트의 기본 스타일을 변경
          .google-visualization-table-tr-head th {
            background-image: none;
            background-color: #f8f9fa;
            padding: 8px 14px;
            border-bottom: px solid #dee2e6;
          }

          .google-visualization-table-td {
            vertical-align: top;
            text-align: center;
            padding: 2px 2px;
            width: 20%;
          }          

          .google-visualization-table-type-number google-visualization-table-td {
            vertical-align: top;
            text-align: center;
            padding: 2px 2px;
            width: 20%;
          }

          .google-visualization-table-td.google-visualization-table-type-number {
            vertical-align: top;
            text-align: center;
            padding: 2px 2px;
            width: 20%;
          }
        `}
    >
      <Chart
        chartType="Table"
        loader={<div>로딩중</div>}
        data={tableData}
        options={{
          showRowNumber: false,
          width: '100%',
          height: '100%',
          allowHtml: true,
          cssClassNames: {},
          sort: 'enable',
        }}
      />      
    </div>
  );
}
