import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function HelmetWrapper({ title }) {
  return (
    <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          titleTemplate={`%s | ${title}`}
        </Helmet>
    </HelmetProvider>
  );

}

export default HelmetWrapper;
