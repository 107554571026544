// import { insertCommas } from './utils/formatter.js';

const _ = require('lodash');

function insertCommas(n) {
    // get stuff before the dot
    let s1 = n.toString();
    var d = s1.indexOf('.');
    var s2 = d === -1 ? s1 : s1.slice(0, d);

    // insert commas every 3 digits from the right
    for (var i = s2.length - 3; i > 0; i -= 3)
        s2 = s2.slice(0, i) + ',' + s2.slice(i);

    // append fractional part
    if (d !== -1)
        s2 += s1.slice(d);

    return s2;
}

function getFormat(type, value) {        
    if (type === 'DATE') {
        const y = value.substr(0, 4);
        const m = value.substr(4, 2);
        const d = value.substr(6, 2);
        return [y, m, d].join('-');
    }else if (type === 'TIME') {
        const h = value.substr(0, 2);
        const m = value.substr(2, 2);
        const s = value.substr(4, 2);
        return [h, m, s].join(':');
    }else if (type === 'TIME_HM') {
        const h = value.substr(0, 2);
        const m = value.substr(2, 2);      
        return [h, m].join(':');
  }
}

function compareBasicRate(x, y) {
    if (document.getElementById('select_exchange').value === 'DOLLAR') {
        if (x.basic_rate_dollar > y.basic_rate_dollar) {
            return -1;
        } else if (x.basic_rate_dollar < y.basic_rate_dollar) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'EURO') {
        if (x.basic_rate_euro > y.basic_rate_euro) {
            return -1;
        } else if (x.basic_rate_euro < y.basic_rate_euro) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'YEN') {
        if (x.basic_rate_yen > y.basic_rate_yen) {
            return -1;
        } else if (x.basic_rate_yen < y.basic_rate_yen) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'GBP') {
        if (x.basic_rate_gbp > y.basic_rate_gbp) {
            return -1;
        } else if (x.basic_rate_gbp < y.basic_rate_gbp) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'CNY') {
        if (x.basic_rate_cny > y.basic_rate_cny) {
            return -1;
        } else if (x.basic_rate_cny < y.basic_rate_cny) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'AUD') {
        if (x.basic_rate_aud > y.basic_rate_aud) {
            return -1;
        } else if (x.basic_rate_aud < y.basic_rate_aud) {
            return 1;
        }
    }else if (document.getElementById('select_exchange').value === 'CAD') {
        if (x.basic_rate_cad > y.basic_rate_cad) {
            return -1;
        } else if (x.basic_rate_cad < y.basic_rate_cad) {
            return 1;
        }
    }
    return 0;
}

function getDataLoader(exchangeData) {
    if (_.isEmpty(exchangeData)) {
        return;
    }
    
    const groupedByGubun    = _.groupBy(exchangeData, 'gubun');
    const groupedByChannel  = _.groupBy(exchangeData, 'channel');
    const exchangeReal      = groupedByChannel['국민은행'];
    // const exchangeReal      = groupedByGubun['REAL'];
    const exchangeBank      = groupedByGubun['BANK'];
    const tableData_real    = getRealData(exchangeReal);
    const tableData_bank    = getBankData(exchangeBank, exchangeReal);
    
    return {
        tableData_real,
        tableData_bank,
    };
}

// 실시간 테이블데이터
function getRealData(exchangeReal) {
    const exchangeRealSorted    = exchangeReal.sort(compareBasicRate);
    const selected_exchange     = document.getElementById('select_exchange').value;
    const rows = exchangeRealSorted.map((x) => {
    return [
            x.channel,
            insertCommas(getBasicRate(selected_exchange, x.basic_rate_dollar, x.basic_rate_euro, x.basic_rate_yen, x.basic_rate_gbp, x.basic_rate_cny, x.basic_rate_aud, x.basic_rate_cad).toFixed(2)),
            // getBaseDateReal(selected_exchange, x.basedate, x.basedate_euro, x.basedate_yen, x.basedate_gbp, x.basedate_gbp, x.basedate_cny, x.basedate_cny, x.basic_rate_aud, x.basic_rate_cad),
            // getBaseTimeReal(selected_exchange, x.basetime, x.basetime_euro, x.basetime_yen, x.basetime_gbp, x.basetime_gbp, x.basetime_cny, x.basetime_cny, x.basic_rate_aud, x.basic_rate_cad),
            getFormat('DATE', x.basedate),
            getFormat('TIME_HM', x.basetime),
        ];
    });
    return rows;
}

// 은행별 테이블데이터
function getBankData(exchangeBank, exchangeReal) {
    const exchangeBankSorted    = exchangeBank.sort(compareBasicRate);
    const selected_exchange     = document.getElementById('select_exchange').value;
    const selected_gap_obj      = document.getElementById('select_gap');
    const selected_gap          = selected_gap_obj.options[selected_gap_obj.selectedIndex].text    
    const gapBasicRate          = getGapBasicRate(exchangeReal, selected_exchange, selected_gap);
    
    const rows = exchangeBankSorted.map((x) => {
    return [
            x.channel,
            insertCommas(getBasicRate(selected_exchange, x.basic_rate_dollar, x.basic_rate_euro, x.basic_rate_yen, x.basic_rate_gbp, x.basic_rate_cny, x.basic_rate_aud, x.basic_rate_cad).toFixed(2)),
            //insertCommas(getBankBasicGap(selected_exchange, x.basic_rate_dollar, x.basic_rate_euro, x.basic_rate_yen, gapBasicRate).toFixed(2)),
            Number(getBankBasicGap(selected_exchange, x.basic_rate_dollar, x.basic_rate_euro, x.basic_rate_yen, x.basic_rate_gbp, x.basic_rate_cny, x.basic_rate_aud, x.basic_rate_cad, gapBasicRate)),
            getFormat('DATE', x.basedate),
            getFormat('TIME_HM', x.basetime),
        ];
    });
    return rows;
}

function getBankBasicGap(selected_exchange, dollar, euro, yen, gbp, cny, aud, cad, realBasicRate) {
    let rtn = 0;
    if (realBasicRate > 0) {
        rtn = getBasicRate(selected_exchange, dollar, euro, yen, gbp, cny, aud, cad) - realBasicRate;
    }    
    return Number(rtn);
}

function getBasicRate(selected_exchange, dollar, euro, yen, gbp, cny, aud, cad) {
    let rtn = null;
    if (selected_exchange === 'DOLLAR'){
        rtn = dollar;
    }else if (selected_exchange === 'EURO'){
        rtn = euro;
    }else if (selected_exchange === 'YEN'){
        rtn = yen;
    }else if (selected_exchange === 'GBP'){
        rtn = gbp;
    }else if (selected_exchange === 'CNY'){        
        rtn = cny;
    }else if (selected_exchange === 'AUD'){        
        rtn = aud;
    }else if (selected_exchange === 'CAD'){        
        rtn = cad;
    }
    return Number(rtn);
}

function getBaseDateReal(selected_exchange, basedate, basedate_euro, basedate_yen, basedate_gbp, basedate_cny, basedate_aud, basedate_cad) {
    let rtn = null;
    if (selected_exchange === 'DOLLAR'){
        rtn = basedate;
    }else if (selected_exchange === 'EURO'){
        rtn = basedate_euro;
    }else if (selected_exchange === 'YEN'){
        rtn = basedate_yen;
    }else if (selected_exchange === 'GBP'){
        rtn = basedate_gbp;
    }else if (selected_exchange === 'CNY'){
        rtn = basedate_cny;
    }else if (selected_exchange === 'AUD'){
        rtn = basedate_aud;
    }else if (selected_exchange === 'CAD'){
        rtn = basedate_cad;
    }
    return getFormat('DATE', rtn);
}

function getBaseTimeReal(selected_exchange, basetime, basetime_euro, basetime_yen, basetime_gbp, basetime_cny, basetime_aud, basetime_cad) {
    let rtn = null;
    if (selected_exchange === 'DOLLAR'){
        rtn = basetime;
    }else if (selected_exchange === 'EURO'){
        rtn = basetime_euro;
    }else if (selected_exchange === 'YEN'){
        rtn = basetime_yen;
    }else if (selected_exchange === 'GBP'){
        rtn = basetime_gbp;
    }else if (selected_exchange === 'CNY'){
        rtn = basetime_cny;
    }else if (selected_exchange === 'AUD'){
        rtn = basetime_aud;
    }else if (selected_exchange === 'CAD'){
        rtn = basetime_cad;
    }
    return getFormat('TIME_HM', rtn);
}

function getGapBasicRate(exchangeReal, selected_exchange, selected_gap) {
    let rtn = null;    
    if (selected_exchange === 'DOLLAR'){
      rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_dollar);
    }else if (selected_exchange === 'EURO'){
      rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_euro);
    }else if (selected_exchange === 'YEN'){
      rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_yen);
    }else if (selected_exchange === 'GBP'){
        rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_gbp);        
    }else if (selected_exchange === 'CNY'){
        rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_cny);        
    }else if (selected_exchange === 'AUD'){
        rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_aud);        
    }else if (selected_exchange === 'CAD'){
        rtn = exchangeReal.filter((x) => x.channel === selected_gap).map((x) => x.basic_rate_cad);        
    }
    return Number(rtn);
  }

module.exports = {
    getDataLoader,
};