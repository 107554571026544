
export function getFormat(type, value) {        
    if (type === 'DATE') {
        const y = value.substr(0, 4);
        const m = value.substr(4, 2);
        const d = value.substr(6, 2);
        return [y, m, d].join('-');
    }else if (type === 'TIME') {
        const h = value.substr(0, 2);
        const m = value.substr(2, 2);
        const s = value.substr(4, 2);
        return [h, m, s].join(':');
    }else if (type === 'TIME_HM') {
      const h = value.substr(0, 2);
      const m = value.substr(2, 2);      
      return [h, m].join(':');
  }
}

export function insertCommas(n) {
    // get stuff before the dot
    let s1 = n.toString();
    var d = s1.indexOf('.');
    var s2 = d === -1 ? s1 : s1.slice(0, d);
  
    // insert commas every 3 digits from the right
    for (var i = s2.length - 3; i > 0; i -= 3)
      s2 = s2.slice(0, i) + ',' + s2.slice(i);
  
    // append fractional part
    if (d !== -1)
      s2 += s1.slice(d);
  
    return s2;
  }
