import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppMain from './AppMain';
// import AppRepair from './AppRepair';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppMain />} />
        {/* <Route path="/" element={<AppRepair />} />
        <Route path="/kkh" element={<AppMain />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
