/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Container } from 'react-bootstrap';
import { CustomSelect } from './select';
import Form from 'react-bootstrap/Form';

export function Slide(props) {    
  const { title, children, id, select_title } = props;  
  const options = getOption(select_title);
  let save_check_real = false;
  let save_check_bank = false;
  
  let select_default  = (select_title === 'select_exchange') ? 'DOLLAR' : 'INVESTING';  
  if(select_title === 'select_exchange' && get_localStorage('currency') !== '') {    
    select_default = get_localStorage('currency');
    save_check_real = true;
  }else if(select_title === 'select_gap' && get_localStorage('gap_target') !== '') {
    select_default = get_localStorage('gap_target');
    save_check_bank = true;
  }  

  function getOption(select_title) {
    if (select_title === 'select_exchange') {      
      return [
        { value: "DOLLAR"	, name: "달러" },        
        { value: "YEN"		, name: "엔"   },
        { value: "EURO"		, name: "유로" },
        { value: "GBP"		, name: "파운드" },
        { value: "CNY"		, name: "위안화" },
        { value: "AUD"		, name: "호주달러" },
        { value: "CAD"		, name: "캐나다달러" },
      ];
    }else if (select_title === 'select_gap') {      
      return [
        // { value: "INVESTING"	, name: "인베스팅" },
        // { value: "YAHOO"		  , name: "야후" },
        // { value: "GOOGLE"		  , name: "구글" },
        { value: "KB"		  , name: "국민은행" },
      ];
    }    
  }
  
  function callSlide(callType, value) {
    if (callType === 'select_exchange') {   // 실시간환율(통화변경)
      if(document.getElementById('cb_save_real').checked) {
        set_localStorage('currency', value);
      }
      props.callApp(value);
    }else if (callType === 'select_gap') {  // 은행별환율(갭기준데이터변경)
      if(document.getElementById('cb_save_bank').checked) {
        set_localStorage('gap_target', value);
      }
      props.callApp(value);
    }
  }

  function set_localStorage(key, value) {
    window.localStorage.setItem(key, value);
  }

  function get_localStorage(key) {
    return window.localStorage.getItem(key);
  }

  function checkValue(e) {
    if(e.target.checked) {
      set_localStorage('currency', document.getElementById('select_exchange').value);
    }else{
      set_localStorage('currency', '');
    }
  }

  function checkValue_gap(e) {
    if(e.target.checked) {
      set_localStorage('gap_target', document.getElementById('select_gap').value);
    }else{
      set_localStorage('gap_target', '');
    }
  }
  
  return (
    <div
      id={id}
      css={css`        
        border-bottom: 1px solid pink;
        padding-top: 5px;        
      `}
    >
      <Container
          css={css`
            text-align: right;
            background-color: white;
            border-radius: 5px;
            padding-top: 8px;
            padding-left: 55px;
            padding-bottom: 0px;
            border: 1px solid #dee2e6;

            h2 {
              padding-top: 2px;
              font-size: 17px;
            }

            .form-check-label {             
              margin: 3px 0px;    
            }
          `}
      > 
        <div className="row">
          <div className="col">
            <h2>{title}</h2>
          </div>
          <div
            css={css` padding-left: 10px;`}
          >
            <CustomSelect id={select_title} options={options} callSlide={callSlide} defaultValue={select_default} />
          </div>
          <div className="col"
            css={css`
              padding-top: 2px;
              font-size: 12px;
              text-align: left;
            `}
          >
            {select_title === 'select_exchange' ?
                <Form.Check id="cb_save_real" onChange={checkValue} type="checkbox" defaultChecked={save_check_real} label="저장" />
              : ''
            }
            {select_title === 'select_gap' ?              
                <Form.Check id="cb_save_bank" onChange={checkValue_gap} type="checkbox" defaultChecked={save_check_bank} label="저장" />
              : ''
            }
          </div>
        </div>
        <div>{children}</div>
      </Container>
    </div>
  );
}
