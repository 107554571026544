/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Slide } from './components/slide';
import { useEffect, useState } from 'react';
import { TableExchangeRate } from './components/exchangerate-table';
import { getFormat } from './utils/formatter';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { ArrowClockwise, CurrencyExchange, InfoCircleFill, CupHotFill } from 'react-bootstrap-icons';
import HelmetWrapper from './components/helmet-wrapper';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const { getDataLoader } = require('./dataloader');
let timerId = null;

export const AppMain = () => {
    const [exchangeRateData, setExchangeRateData] = useState([]);
    const [tableData_real, setTableData_real]     = useState([]);
    const [tableData_bank, setTableData_bank]     = useState([]);
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const config = {        
        baseURL: process.env.REACT_APP_BASE_URL || 'https://demeter0111.gabia.io',
    };
    
    const fetchData = async () => {        
        const response = await axios.get(config.baseURL + '/exchange-rate-real');      
        setExchangeRateData(response.data.result);
        setLoadData(response.data.result);        
    }

    useEffect(() => {    
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function setLoadData(data) {
        const loadData = getDataLoader(data);
        setTableData_real(loadData['tableData_real']);
        setTableData_bank(loadData['tableData_bank']);
    }

    function getUpdateDt() {    
        if (exchangeRateData.length === 0)  {
        return '-';
        }
        const update_date = String(exchangeRateData.filter((x) => x.channel === '국민은행').map((x) => x.regdate));
        const update_time = String(exchangeRateData.filter((x) => x.channel === '국민은행').map((x) => x.regtime));        
        return new Date(getFormat('DATE',String(update_date)) + ' ' + getFormat('TIME',String(update_time))).toLocaleString()    
    }
    

    function callApp() {    
        setLoadData(exchangeRateData);
    }
    
    function btn_reload() {    
        window.location.reload();
    }
    
    function checkValue(e) {
        if(e.target.checked) {            
            if (timerId !== null ) return;
            timerId = setInterval( () => {            
                fetchData();
            }, 1000 * 30)            
        }else{
            if (timerId === null ) return;            
            clearInterval(timerId);
            timerId = null;
        }
    }

    return (
        <div>
        <HelmetWrapper title={'MoneyChangeGood - 머니체인지'} />
        <div css={css`position: absolute; background-color: black; width: 100%; height: 120px; z-index: -99;`} >
            <h1 css={css` padding-top: 10px; padding-bottom: 5px; color: white; text-align: center; font-size: 21px; `} >
                <CurrencyExchange />  머니체인지
            </h1>
                <div css={css` text-align: center; display: flex; justify-content: center; `} >
                    <div>
                        <p className="text-center text-white"> 
                        <span css={css` cursor : pointer;`} onClick={btn_reload} >
                            <ArrowClockwise />
                        </span>
                        업데이트 : {getUpdateDt()}
                        </p>
                    </div>
                    <div>
                        <label css={css` padding-top: 5px; padding-left: 10px; font-size: 12px; color: white; `}>
                            <input type="checkbox" onChange={(e) => checkValue(e)} />
                            &nbsp;자동갱신
                        </label>
                    </div>
                </div>

            <Slide id={"title_real"} title="비교환율" select_title="select_exchange" callFunc="callSlideExchange" callApp={callApp} />
            <TableExchangeRate id="exchangerate-real-table" exchangeData={tableData_real} />
            <Slide id={"title_bank"} title="은행별환율" select_title="select_gap" callFunc="callSlideGap" callApp={callApp} />
            <TableExchangeRate id="exchangerate-bank-table" exchangeData={tableData_bank} />

            {/* <div css={css` padding-top: 3px; padding-left: 10px; max-width: 640px; margin : 0 auto; font-size: 13px; font-weight: bold; cursor : pointer; color: #007bff; `} onClick={handleShow}>
                <CupHotFill />  개발자에게 고마움 전하기            
            </div> */}
            <div css={css` padding-top: 1px; padding-left: 10px; max-width: 640px; margin : 0 auto; font-size: 12px; font-weight: bold; color: #F15F5F; `}>
                <InfoCircleFill />  2분 주기로 갱신되며 실시간환율과 차이가 있을 수 있습니다.
            </div>
            <div css={css` padding-top: 1px; padding-left: 26px; max-width: 640px; margin : 0 auto; font-size: 12px; font-weight: bold; color: #F15F5F; `}>
            환전시 매매기준율을 최종적으로 확인하시기 바랍니다.
            </div>
            <div css={css` padding-top: 1px; padding-left: 10px; max-width: 640px; margin : 0 auto; font-size: 12px; font-weight: bold; color: #F15F5F; `}>            
                <InfoCircleFill />  실시간환율(삭제) 토스뱅크(삭제)
                <span css={css` padding-left: 10px;margin : 0 auto; font-size: 12px; font-weight: bold; cursor : pointer; color: #007bff; `} onClick={handleShow}>- 상세보기(클릭)</span>
            </div>
            <div css={css` padding-top: 10px; max-width: 640px; margin : 0 auto; `}>
                <iframe src="https://ads-partners.coupang.com/widgets.html?id=617278&template=carousel&trackingCode=AF3350395&subId=&width=100%25&height=100" width="100%" height="100" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"></iframe>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>안내</Modal.Title>
                </Modal.Header>
                <Modal.Body>              
                <div class="row">
                    <div class="col">
                    <div class="card border-bottom-primary mb-4">
                        <div class="card-header py-1">
                            <h6 class="m-0 font-weight-bold text-primary">업데이트</h6>
                        </div>
                        <div class="card-body text-left">
                            <div css={css` padding-top: 2px; padding-left: 1px; max-width: 640px; margin : 0 auto; font-size: 15px; font-weight: bold; color: #F15F5F; `}>
                                [2024-03-04]
                            </div>
                            <div css={css` font-size: 14px; `}>
                                토스뱅크(삭제)
                            </div>
                            <div css={css` padding-top: 10px; padding-left: 1px; max-width: 640px; margin : 0 auto; font-size: 15px; font-weight: bold; color: #F15F5F; `}>
                                [2024-03-02]
                            </div>
                            <div css={css` font-size: 14px; `}>
                                은행별환율 추가(SC은행), 실시간환율 삭제
                            </div>
                            <div css={css` padding-top: 10px; padding-left: 1px; max-width: 640px; margin : 0 auto; font-size: 15px; font-weight: bold; color: #F15F5F; `}>
                                [2024-02-29]
                            </div>
                            <div css={css` font-size: 14px; `}>
                                실시간환율 조회시 지속적으로 문제가 발생되어
                            </div>
                            <div css={css` font-size: 14px; `}>
                                구글을 제외하고 모두 삭제되었습니다.
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="card border-bottom-primary mb-4">
                            <div class="card-header py-1">
                                <h6 class="m-0 font-weight-bold text-primary">서비스종료안내</h6>
                            </div>
                            <div class="card-body text-left">
                            <div css={css` font-size: 14px; `}>
                                반복적인 서버문제에 대응할 여력이 되지 않아
                            </div>
                            <div css={css` font-size: 14px; `}>
                                2024년을 마지막으로 서비스가 종료될 예정입니다.
                            </div>
                            <div css={css` font-size: 14px; `}>
                                그동안 후원 및 응원해주신 모든분들께 감사드립니다.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>              
                </Modal.Footer>
            </Modal>
        </div>
        </div>
    );

};

export default AppMain;
